import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
    mode: "history",
    routes: [
        {
            path: "/",
            component: () => import("@/view/pages/Login"),
            children: [
                {
                    name: "login",
                    path: "/login",
                    component: () => import("@/view/pages/Login")
                }
            ]
        },
        {
            path: "/",
            redirect: "/dashboard",
            component: () => import("@/view/layout/Layout"),
            children: [
                {
                    path: "/dashboard",
                    name: "dashboard",
                    component: () => import("@/view/pages/Dashboard.vue")
                },
                {
                    path: "/ChooseDormitory",
                    name: "选择宿舍",
                    component: () => import("@/view/pages/ChooseDormitory.vue")
                },
                {
                    path: "/ChangePassword",
                    name: "修改密码",
                    component: () => import("@/view/pages/ChangePassword.vue")
                },
                {
                    path: "/main",
                    name: "main",
                    component: () => import("@/view/pages/main.vue")
                },
                {
                    path: "/myInfo",
                    name: "myInfo",
                    component: () => import("@/view/MyInfo.vue")
                },
                {
                    path: "/ExtraInfo",
                    name: "ExtraInfo",
                    component: () => import("@/view/ExtraInfo.vue")
                },
            ]
        },
        {
            path: "*",
            redirect: "/404"
        },
        {
            // the 404 route, when none of the above matches
            path: "/404",
            name: "404",
            component: () => import("@/view/pages/error/Error-1.vue")
        },
        {
            // the 404 route, when none of the above matches
            path: "/SysClose",
            name: "系统关闭",
            component: () => import("@/view/pages/error/SysClose.vue")
        }
    ]
});

const router = new Router();

// 前置路由守卫，防止通过url直接访问需要登录的页面
router.beforeEach((to, from, next) => {
    // console.log(to.path);
    if (to.path === "/login") {
        next();
    } else {
        let token = sessionStorage.getItem("systemToken");
        if (token === null || token === "") {
            next("/login");
        } else {
            next();
        }
    }
});