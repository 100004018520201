import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import Axios from 'axios'
import './assets/element/style/theme/index.css';
import ElementUI from 'element-ui';

import md5 from './plugins/md5';
// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
import ClipboardJS from "clipboard";
// Vue 3rd party plugins
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(md5);

Vue.config.productionTip = true;
Vue.use(ElementUI)
window.PerfectScrollbar = PerfectScrollbar;
window.ClipboardJS = ClipboardJS;
Vue.prototype.$axios = Axios

Axios.interceptors.response.use(function (response) {
    switch (response.status) {
        case 200:
            switch (response.data.status) {
                case 0:
                    return response.data;
                case 10:
                    return response.data;
                case 15:
                    return response.data;
                case 6:
                    Vue.prototype.$message({
                        message: "身份验证信息过期，请重新登录！",
                        type: "error",
                    });
                    location.href = "/";
                    sessionStorage.clear();
                    return null;
                case 8:
                    location.href = "/";
                    sessionStorage.clear();
                    return null;
                case 16:
                    return response.data;
                case undefined:
                    return response.data;
                default:
                    return response.data;
            }
            break;
        case 404 :
            return response.data;
        case 403:
            location.href = "/"
            return null;
    }


});

// router.beforeEach((to, from, next) => {
//   setTimeout(() => {
//     window.scrollTo(0, 0);
//   }, 100);
// });

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount("#app");
