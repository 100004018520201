const state = {
    firstLogin: false,
    choose:false,
    avatarPath:""
};

const getters = {
    getLoginState(state) {
        return state.firstLogin;
    },
    getChooseState(){
        return state.choose;
    },
    getAvatarPath(){
        return state.avatarPath;
    }

};

const actions = {
    changeFirstLogin(context, firstLogin) {
        context.commit('changeFirstLogin', firstLogin);
    },
    changeChooseState(context,choose){
        context.commit('changeChooseState',choose);
    },
    changeAvatarPath(context,avatarPath){
        context.commit('changeAvatarPath',avatarPath);
    }
};

const mutations = {
    changeFirstLogin(state, firstLogin) {
        state.firstLogin = firstLogin;
    },
    changeChooseState(state, choose) {
        state.choose = choose;
    },
    changeAvatarPath(state,avatarPath){
        state.avatarPath = avatarPath
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};